import { useSuspenseQuery } from "~/queries/useSuspenseQuery";

export const useHomepageQuery = () => {
    const { locale } = useI18n();

    return useSuspenseQuery({
        queryKey: ["offer"],
        queryFn: async () =>
            await $apiFetch(`/api/homepage-data`, {
                params: { locale: locale.value },
            }),
        refetchOnWindowFocus: false,
    });
};
