<template>
  <div>
    <!-- v-if="!store.isOpen" -->
    <div
      :class="[
        'flex w-full flex-row items-center gap-4',
        !props.slim && 'bg-white',
      ]"
    >
      <button
        :class="[
          !props.slim &&
            'bg-blue-700 p-[18px] text-white transition-colors hover:bg-blue-800',
        ]"
        @click="emit('play')"
      >
        <div
          :class="['flex  justify-center', props.slim ? 'h-5 w-5' : 'h-6 w-6']"
        >
          <svg
            viewBox="0 0 20 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            class="h-full"
          >
            <path
              id="Vector"
              d="M19.6359 12.5802L0.364258 0.580566V24.5806L19.6359 12.5802Z"
              fill="currentColor"
            />
          </svg>
        </div>
      </button>
      <div class="mr-6 h-1 w-full bg-neutral-300"></div>
    </div>
    <!-- <div v-else>Prehrava se</div> -->
  </div>
</template>
<script setup lang="ts">
const props = defineProps<{ slim?: boolean }>();
const emit = defineEmits(["play"]);
// const store = useAudioPlayerStore();
</script>
